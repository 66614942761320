<template>
	<div class="view pa24" >
		<div style="display: flex;flex-wrap: wrap;"> 
			<div class="mr10">
				<span class="mr10">订单号</span>
				<el-input clearable class="w220 mr10" v-model="search.name" placeholder="请输入订单号" />
			</div>
			<!-- <div class="mr10"> 
				<span class="mr10">银行卡</span>
				<el-input clearable class="w220 mr10" v-model="search.bank" placeholder="请输入银行卡" />
			</div> -->
			<!-- <div class="mr10">
				<span class="mr10">金额</span>
				<el-input clearable class="w220 mr10" v-model="search.money" placeholder="请输入金额" />
			</div> -->
			<div class="mr10">
				<span class="mr10">状态</span>
				<el-select clearable v-model="search.status" class="w220 mb10 mr10" placeholder="请选择状态">
					<el-option v-for="(item,index) in statusiList" :key="index+'info'" :label="item.name"
						:value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="mr10">
				<span class="mr10">申请时间</span>
				<el-date-picker  :picker-options="pickerOptions" v-model="search.date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
			<el-button class="ml10 h34" type="primary" @click="upSearch">查询</el-button>
			<el-button class="mr10 h34" type="success" @click="
	     centerDialogVisible = true;
	     bdetermine = true;
	     editUpdate = false;
	   ">+ 入金申请</el-button>
			<el-button class="mr10 h34" type="danger" @click="daochu">导出</el-button>
		</div>
		<div class="mt20">
			 <el-tag effect="plain" class="mr10">成功次数：{{info.success_count}}</el-tag>
			 <el-tag effect="plain" type="success">成功金额：{{info.success_money_count}}</el-tag>
		</div>
		<commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
			<template v-slot:table>
				<el-table-column prop="order_no" align="center" label="订单"  />
				<el-table-column prop="money" align="center" label="金额" />
				<el-table-column prop="create_time" align="center" label="创建时间" />
				<el-table-column prop="verify_time" align="center" label="处理时间" />
				<el-table-column prop="createName" align="center" label="状态">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status==0" type="primary">{{scope.row.status_text}}</el-tag>
						<el-tag v-if="scope.row.status==1" type="success">{{scope.row.status_text}}</el-tag>
						<el-tag v-if="scope.row.status==2" type="danger">{{scope.row.status_text}}</el-tag>
						<el-tag v-if="scope.row.status==3" type="warning">{{scope.row.status_text}}</el-tag>
					</template>
				</el-table-column>
			</template>
		</commonTable>
		<el-dialog title="入金申请" :visible.sync="centerDialogVisible" :modal-append-to-body="false" width="50%" center
			@close="close">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
				<el-form-item label="金额" prop="money">
					<el-input clearable v-model="ruleForm.money" placeholder="请输入金额"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine" v-if="bdetermine">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import Moment from 'moment';
	import {
		mapState
	} from 'vuex';
	import {
		dfrechargeList,
		dfrechargeAdd,
	} from "@/api/settlement";
	let ac;
	export default {
		name: 'epos',
		data() {
			return {
        pickerOptions: {
          shortcuts: [{
            text: '今日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '昨日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, start]);
            }
          },{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
				info:{},
				search:{
					name:'',
					status:'',
					date:''
				},
				statusiList: [
					{name:'待处理 ',value:0},
					{name:'已处理 ',value:1},
					{name:'已驳回 ',value:2},
					{name:'已冲正 ',value:3}
				],
				tableData: [],
				currentPage: 1, //当前页
				pageSize: 10, //显示条数
				loading: false, //表格加载
				total: 0, //总条数
				centerDialogVisible: false,
				bEdit: false,
				bdetermine: true,
				editUpdate: true,
				ruleForm: {
					money: "",
				},
				rules: {
					money: [{
						required: true,
						message: "请输入金额",
						trigger: "blur"
					}],
				},
				id:''
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			this.queryPage();
		},

		methods: {
			async queryPage(payload) {
				let data = {
					page_size: this.pageSize,
					page_no: this.currentPage,
					 ...payload
				};
				try {
					this.loading = true;
					const result = await dfrechargeList(data);
					if (result.code == 1) {
            this.loading = false;
            const {
              count,
              lists,
              extend
            } = result.data;
            this.tableData = lists;
            this.total = count;
            this.info = extend;
          }else {
            this.$message.error(result.msg)
            this.tableData = []
            this.loading = false;
          }
				} catch (error) {
          this.tableData = []
          this.loading = false;
				}
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.queryPage();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.queryPage();
			},
			edit(val, b) {
				let data = JSON.parse(JSON.stringify(val));
				this.centerDialogVisible = true;
				this.ruleForm.money = data.money;
				this.bdetermine = b;
				this.bEdit = b;
				this.id = data.id
			},
			/**@method 添加 */
			determine() {
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								money: this.ruleForm.money,
							};
							this.$message({
								message: "正在保存",
							});
							if (this.editUpdate) {
								await dfrechargeAdd(data);
							} else {
								await dfrechargeAdd(data);
							}
							this.centerDialogVisible = false;
							this.$message.closeAll();
							this.$message({
								message: "保存成功",
								type: "success",
							});
							await this.queryPage();
						} catch (error) {
							this.$message.closeAll();
							this.$message({
								message: error.message,
								type: "error",
							});
							this.centerDialogVisible = false;
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			/**@method 删除
			 * @param {String}
			 */
			async del(val) {
				try {
					await delWindowsConfig({
						windowsConfigId: val
					});
					await this.queryPage();
				} catch (error) {
					console.log(error);
				}
			},
			/**@method 模态框关闭的回调 */
			close() {
				(this.ruleForm = {
					money: "",
				}),
				this.centerDialogVisible = false;
			},
			upSearch(){
				this.currentPage = 1;
				let data = {
					order_no:this.search.name,
					status:this.search.status,
				};
				if(this.search.date) {
					data.start_time = Moment(this.search.date[0]).format('YYYY-MM-DD 00:00:00');
					data.end_time = Moment(this.search.date[1]).format('YYYY-MM-DD 23:59:59');
				}
				this.queryPage(data);
			},
			daochu(){
				let data = {
					order_no:this.search.name,
					status:this.search.status,
					export:2
				};
				if(this.search.date) {
					data.start_time = Moment(this.search.date[0]).format('YYYY-MM-DD 00:00:00');
					data.end_time = Moment(this.search.date[1]).format('YYYY-MM-DD 23:59:59');
				}
				dfrechargeList(data)
					.then((res) => {
						window.open(res.data.url)
					})
					.catch((err) => {
						console.log(err)
						this.$message.error(err.msg);
					});
			}
		},
	};
</script>

<style lang="scss" scoped>
</style>